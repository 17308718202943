import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const SmartItemisedAnswer = (props) => {
  const {
    id,
    summary,
    summaryType, // 'plain' or 'markdown'
    typingDelay,
    animationDelay,
    table,
    barChart,
    pieChart,
    lineChart,
    forecastChart,
    sql,
    imageLinks,
    setProps,
    className,
    style,
    isTableOpen,
    isBarChartOpen,
    isPieChartOpen,
    isLineChartOpen,
    isForecastOpen,
    isSqlOpen
  } = props;

  // State for typing animation of the summary
  const [displayedSummary, setDisplayedSummary] = useState(
    typeof summary === 'string' ? '' : summary
  );

  // State to force re-render on summary change
  const [key, setKey] = useState(0);

  // Reset all states when summary changes
  useEffect(() => {
    // Reset the key to force a full re-render
    setKey(prevKey => prevKey + 1);
    
    // Reset displayed summary
    setDisplayedSummary(typeof summary === 'string' ? '' : summary);
    
    // Reset visible sections
    setVisibleSections({
      table: false,
      barChart: false,
      pieChart: false,
      lineChart: false,
      forecastChart: false,
      sql: false
    });
    
    // Type out the summary if it's a string
    if (typeof summary === 'string') {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedSummary(summary.slice(0, index + 1));
        index++;
        if (index >= summary.length) {
          clearInterval(interval);
        }
      }, typingDelay);
      
      return () => clearInterval(interval);
    }
  }, [summary, typingDelay]);

  // Choose how to render summary
  const renderedSummary =
    summaryType === 'markdown' ? (
      <ReactMarkdown>{displayedSummary}</ReactMarkdown>
    ) : (
      displayedSummary
    );

  // State to control sequential visibility of sections
  const [visibleSections, setVisibleSections] = useState({
    table: false,
    barChart: false,
    pieChart: false,
    lineChart: false,
    forecastChart: false,
    sql: false
  });

  // Load sections only after the summary animation completes
  useEffect(() => {
    if (displayedSummary === summary) {
      const sectionOrder = ['table', 'barChart', 'pieChart', 'lineChart', 'forecastChart', 'sql'];
      const timers = sectionOrder.map((section, index) =>
        setTimeout(() => {
          setVisibleSections(prev => ({ ...prev, [section]: true }));
        }, (index + 1) * animationDelay) // Delay between sections
      );
      return () => timers.forEach(timer => clearTimeout(timer));
    }
  }, [displayedSummary, summary, animationDelay]);

  const toggleSection = useCallback((section) => {
    // Updated toggle logic to use the individual section props
    if (setProps) {
      switch (section) {
        case 'table':
          setProps({ isTableOpen: !isTableOpen });
          break;
        case 'barChart':
          setProps({ isBarChartOpen: !isBarChartOpen });
          break;
        case 'pieChart':
          setProps({ isPieChartOpen: !isPieChartOpen });
          break;
        case 'lineChart':
          setProps({ isLineChartOpen: !isLineChartOpen });
          break;
        case 'forecastChart':
          setProps({ isForecastOpen: !isForecastOpen });
          break;
        case 'sql':
          setProps({ isSqlOpen: !isSqlOpen });
          break;
        default:
          break;
      }
    }
  }, [setProps, isTableOpen, isBarChartOpen, isPieChartOpen, isLineChartOpen, isForecastOpen, isSqlOpen]);

  const isSectionOpen = useCallback((section) => {
    // Helper function to check if a section is open
    switch (section) {
      case 'table':
        return isTableOpen;
      case 'barChart':
        return isBarChartOpen;
      case 'pieChart':
        return isPieChartOpen;
      case 'lineChart':
        return isLineChartOpen;
      case 'forecastChart':
        return isForecastOpen;
      case 'sql':
        return isSqlOpen;
      default:
        return false;
    }
  }, [isTableOpen, isBarChartOpen, isPieChartOpen, isLineChartOpen, isForecastOpen, isSqlOpen]);

  const renderSection = useCallback((title, section, content, iconSrc = null) => {
    if (!content) return null;
    
    const isExpanded = isSectionOpen(section);
    
    return (
      <div className="smart-answer-section" style={sectionStyle}>
        <div 
          className="smart-answer-section-header" 
          style={sectionHeaderStyle} 
          onClick={() => toggleSection(section)}
        >
          {iconSrc && (
            <img src={iconSrc} alt={title} style={iconStyle} />
          )}
          <span style={sectionTitleStyle}>{title}</span>
          <span
            style={{
              ...expandButtonStyle,
              transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          >
            {imageLinks?.expandArrow ? (
              <img src={imageLinks.expandArrow} alt={title} style={expandArrowStyle} />
            ) : '▼'}
          </span>
        </div>
        
        {isExpanded && (
          <div className="smart-answer-section-content" style={sectionContentStyle}>
            {content}
          </div>
        )}
      </div>
    );
  }, [isSectionOpen, toggleSection, imageLinks]);

  return (
    <div 
      id={id}
      key={key} // This forces a complete re-render when the key changes
      className={`smart-answer ${className || ''}`}
      style={{
        fontFamily: 'Inter, sans-serif',
        ...style
      }}
    >
      {/* Summary section with typing animation */}
      {displayedSummary && (
        <div className="smart-answer-summary" style={summaryStyle}>
          {renderedSummary}
        </div>
      )}
      
      {/* Expandable sections loaded sequentially */}
      {visibleSections.table && renderSection(
        'See result in tabular form', 
        'table', 
        table, 
        imageLinks?.table
      )}
      {visibleSections.barChart && renderSection(
        'See result in bar chart', 
        'barChart', 
        barChart, 
        imageLinks?.barChart
      )}
      {visibleSections.pieChart && renderSection(
        'See result in pie chart', 
        'pieChart', 
        pieChart, 
        imageLinks?.pieChart
      )}
      {visibleSections.lineChart && renderSection(
        'See result in time series', 
        'lineChart', 
        lineChart, 
        imageLinks?.lineChart
      )}
      {visibleSections.forecastChart && renderSection(
        'See forecast', 
        'forecastChart', 
        forecastChart,  
        imageLinks?.forecastChart
      )}
      {visibleSections.sql && renderSection(
        'SQL Query', 
        'sql', 
        (<pre style={sqlStyle}>{sql}</pre>), 
        imageLinks?.sql
      )}
    </div>
  );
};

const summaryStyle = {
  marginBottom: '30px',
};

const sectionStyle = {
  marginBottom: '12px',
  borderRadius: '8px',
  overflow: 'hidden'
};

const sectionHeaderStyle = {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: '600',
  fontSize: '14px !important',
  color:'#0C6DFD !important'
};

const expandButtonStyle = {
  fontSize: '14px !important',
  fontWeight: 'bold',
  marginLeft: 'auto'
};

const sectionTitleStyle = {
  fontSize: '16px !important',
  marginLeft: '10px'
};

const iconStyle = {
  width: '1.5em',
  height: '1.5em',
  filter: 'invert(40%) sepia(75%) saturate(6000%) hue-rotate(205deg) brightness(101%) contrast(101%)'
};

const expandArrowStyle = {
  width: '1.2em',
  height: '1.2em',
  filter: 'invert(40%) sepia(75%) saturate(6000%) hue-rotate(205deg) brightness(101%) contrast(101%)'
};

const sectionContentStyle = {
  paddingTop: '16px',
  overflow: 'auto'
};

const sqlStyle = {
  padding: '5px',
  fontSize: '16px !important',
  overflowX: 'auto',
  fontFamily: 'monospace'
};

SmartItemisedAnswer.defaultProps = {
  summary: null,
  summaryType: 'plain', // or 'markdown'
  typingDelay: 50,
  animationDelay: 500,
  table: null,
  barChart: null,
  pieChart: null,
  lineChart: null,
  forecastChart: null,
  sql: null,
  className: '',
  style: {},
  imageLinks: {
    expandArrow: null,
    table: null,
    barChart: null,
    pieChart: null,
    lineChart: null,
    forecastChart: null,
    sql: null
  },
  // Default values for individual section states
  isTableOpen: false,
  isBarChartOpen: false,
  isPieChartOpen: false,
  isLineChartOpen: false,
  isForecastOpen: false,
  isSqlOpen: false
};

SmartItemisedAnswer.propTypes = {
  id: PropTypes.string,
  summary: PropTypes.node,
  summaryType: PropTypes.oneOf(['plain', 'markdown']),
  typingDelay: PropTypes.number,
  animationDelay: PropTypes.number,
  table: PropTypes.node,
  barChart: PropTypes.node,
  pieChart: PropTypes.node,
  lineChart: PropTypes.node,
  forecastChart: PropTypes.node,
  sql: PropTypes.node,
  imageLinks: PropTypes.shape({
    expandArrow: PropTypes.string,
    table: PropTypes.string,
    barChart: PropTypes.string,
    pieChart: PropTypes.string,
    lineChart: PropTypes.string,
    forecastChart: PropTypes.string,
    sql: PropTypes.string
  }),
  isTableOpen: PropTypes.bool,
  isBarChartOpen: PropTypes.bool,
  isPieChartOpen: PropTypes.bool,
  isLineChartOpen: PropTypes.bool,
  isForecastOpen: PropTypes.bool,
  isSqlOpen: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  setProps: PropTypes.func
};

export default SmartItemisedAnswer;